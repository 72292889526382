import React, { useState } from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { useMediaQuery } from "@mui/material"
import Grow from "@mui/material/Grow"
import Grid from "@mui/material/Grid"
import { Link } from "gatsby"

const NewsWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.grey,
  width: "100%",
  height: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  transition: "all 0.5s",
  "@media (max-width:1440px) and (min-width:1024px)": {
    padding: "0 45px",
  },
}))

const Wrapper = styled("div")({
  width: "100%",
  maxWidth: "1280px",
  padding: "84px 0 76px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  transition: "all 0.5s",
  "@media (max-width:1024px)": {
    padding: "82px 28px 75px",
  },
  "@media (max-width:576px)": {
    padding: "84px 40px 62px",
  },
})

const NewsAndUpdates = styled(Typography)(({ theme }) => ({
  cursor: "default",
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "18px",
  color: theme.palette.secondary.main,
  textTransform: "uppercase",
  marginBottom: "35px",
  "@media (max-width:1025px)": {
    marginBottom: "38px",
    fontSize: "1rem",
  },
}))

const Title = styled("div")(({ theme, maxhrwidth }) => ({
  cursor: "default",
  display: "flex",
  justifyContent: "flex-start",
  marginRight: maxhrwidth === "true" ? "inherit" : "auto",
  marginBottom: maxhrwidth === "false" ? "33px" : 0,
  "& hr": {
    color: theme.palette.secondary.blue,
    width: maxhrwidth === "true" ? "100%" : "322px",
    height: "4px",
    backgroundColor: theme.palette.secondary.blue,
    margin: "0.8em auto",
  },
  "@media (max-width:576px)": {
    marginRight: 0,
    "& hr": {
      width: "100%",
      height: "3px",
    },
  },
}))

const NewsTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "25px",
  color: theme.palette.secondary.blue,
  whiteSpace: "nowrap",
  marginRight: "18px",
  textTransform: "uppercase",
  "@media (max-width:1025px)": {
    fontSize: "1rem",
  },
  "@media (max-width:576px)": {
    fontSize: "1.13rem",
  },
}))

const OneNews = styled("div")({
  rowGap: "18px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&:hover": {
    "p:first-of-type": {
      color: "#0870E0",
    },
  },
  "@media (max-width:576px)": {
    rowGap: "20px",
  },
})

const NewsBody = styled(Typography)(({ theme }) => ({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "150%",
  color: theme.palette.secondary.main,
  "@media (max-width:1024px)": {
    fontSize: "1rem",
    lineHeight: "26px",
  },
  "@media (max-width:576px)": {
    fontSize: "1.13rem",
  },
}))

const NewsFooter = styled(Typography)(({ theme }) => ({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineGeight: "190%",
  color: theme.palette.secondary.main,
  opacity: 0.6,
  "& span": {
    fontWeight: 400,
  },
  "@media (max-width:1025px)": {
    fontSize: "0.67rem",
  },
  "@media (max-width:576px)": {
    fontSize: "0.76rem",
  },
}))

const Button = styled("div")(({ theme, showbutton }) => ({
  border: `1px solid ${theme.palette.secondary.blue}`,
  color: theme.palette.secondary.blue,
  boxSizing: "border-box",
  borderRadius: "2px",
  backgroundColor: "transparent",
  cursor: "pointer",
  height: "58px",
  width: "220px",
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "19px",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.7s",
  userSelect: "none",
  margin: "76px auto 0",
  display: showbutton === "true" ? "flex" : "none",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#0870E0",
  },
  "&:focus": {
    color: "#FFF",
    backgroundColor: "#0870E0",
  },
  "@media (max-width:576px)": {
    fontSize: "0.88rem",
    maxWidth: "182px",
    height: "50px",
    margin: "42px auto 0",
  },
}))

const News = ({ news, showHeader, mobileMaxNews, strapiId }) => {
  const clean_news = []
  news.edges.forEach(news => {
    var newsLink = news.node.title.toLowerCase()
    .replace('.', '')
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'') + `-${news.node.strapi_id}`
    var timeNow = new Date()
    var timeNews = new Date(news.node.publication_date)
    var delta_time = Math.round(Math.abs((timeNow - timeNews) / 1000 / 60 / 60))
    var timeString
    if (delta_time < 24) {
      if (delta_time === 1 || delta_time === 0){
        timeString = "1 hour ago"
      } else {
        timeString = delta_time.toString() + " hours ago"
      }
    } else {
      timeString = timeNews.toLocaleString('en-us', { day: "numeric", month: 'short', year: "numeric" })
    }
    clean_news.push({
      type: news.node.blog_type.Type,
      title: news.node.title,
      source: news.node.source_name,
      time: timeString,
      link: newsLink,
    })
  })

  const isMobile = useMediaQuery("(max-width: 576px)")
  const [showButton, setShowButton] = useState(false)
  const [extraNews, setExtraNews] = useState([])
  
  let previewData = clean_news.slice(0, 6)

  const showMore = () => {
    setShowButton(false)
    setExtraNews(clean_news.slice(previewData.length, news.length))
  }

  React.useEffect(() => {
    if (previewData.length === clean_news.length) {
      setShowButton(false)
    }
  }, [clean_news.length, extraNews, previewData])

  React.useEffect(() => {
    showHeader ? setShowButton(false) : setShowButton(true)
    if (isMobile) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      previewData = clean_news.slice(0, mobileMaxNews)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NewsWrapper>
      <Wrapper>
        {showHeader ? (
          <NewsAndUpdates>news & updates</NewsAndUpdates>
        ) : (
          <Title maxhrwidth={showHeader === true ? "true" : "false"}>
            <NewsTitle>company news</NewsTitle>
            <hr />
          </Title>
        )}
        <Grid container spacing={4}>
          {previewData.map(oneNew => (
          <Grid key={Math.random() + "news"} item xs={12} sm={4}>
              <Link
                key={Math.random() + "keynews"}
                to={`/${oneNew.link}`}
                style={{ textDecoration: "none" }}
              >
                <OneNews key={Math.random() + "keynew"}>
                  {showHeader ? (
                    <Title
                      key={Math.random() + "keynewtitle"}
                      maxhrwidth={showHeader === true ? "true" : "false"}
                    >
                      <NewsTitle>{oneNew.type}</NewsTitle>
                      <hr />
                    </Title>
                  ) : null}
                  <NewsBody>{oneNew.title}</NewsBody>
                  <NewsFooter>
                    {oneNew.time} {oneNew.source ? " - " : null}
                    <span>{oneNew.source}</span>
                  </NewsFooter>
                </OneNews>
              </Link>
            </Grid>
          ))}
          {extraNews.map(oneNew => (
          <Grid key={Math.random() + "news"} item xs={12} sm={4}>
              <Grow
                key={Math.random() + "grow"}
                in={true}
                {...{ timeout: 1000 }}
              >
                <Link
                  key={Math.random() + "keynews"}
                  to={`/${oneNew.link}`}
                  style={{ textDecoration: "none" }}
                >
                  <OneNews key={Math.random() + "keynew"}>
                    {showHeader ? (
                      <Title
                        key={Math.random() + "keynewtitle"}
                        maxhrwidth={showHeader === true ? "true" : "false"}
                      >
                        <NewsTitle>{oneNew.type}</NewsTitle>
                        <hr />
                      </Title>
                    ) : null}
                    <NewsBody>{oneNew.title}</NewsBody>
                    <NewsFooter>
                      {oneNew.time} {oneNew.source !== "" ? " - " : null}
                      <span>{oneNew.source}</span>
                    </NewsFooter>
                  </OneNews>
                </Link>
              </Grow>
            </Grid>)
          )}
        </Grid>
        <Button showbutton={showButton ? "true" : "false"} onClick={showMore}>
          SHOW MORE
        </Button>
      </Wrapper>
    </NewsWrapper>
  )
}

export default News
