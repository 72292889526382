import React from "react"
import { styled } from "@mui/system"
import { Link } from "gatsby"
import Grid from "@mui/material/Grid"
import Arrow from "./../../../static/svg/Arrow.svg"
import { Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import NavNoiseOne from "../../../static/images/nav1noise.png"
import NavNoiseTwo from "../../../static/images/nav2noise.png"

const Wrapper = styled("nav")({
  width: "100%",
  margin: "0",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})

const BlackWrapper = styled("div")(({ theme }) => ({
  background: "black",
  opacity: 0.4,
  position: "absolute",
  zIndex: 2,
  width: "100%",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
}))

const BlueWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "230px",
  background: theme.palette.secondary.blue,
  mixBlendMode: "screen",
  position: "absolute",
  zIndex: 4,
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
}))

const LinkWrapper = styled(Link)(({ right }) => ({
  zIndex: 999,
  width: "100%",
  maxWidth: "640px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  background: "transparent",
  textDecoration: "none",
  justifyContent: right === "true" ? "flex-end" : "flex-start",
  boxSizing: "content-box",
  "@media (max-width:1336px)": {
    padding: right === "true" ? "0 28px 0 0 " : "0 0 0 28px",
  },
  height: "230px",
  transition: "all 0.7s",
  "& svg": {
    transition: "all 0.7s",
    margin: right === "true" ? "0 0 0 47px" : "0 47px 0 0",
    transform: right === "true" ? "translateX(0) rotate(180deg)" : "rotate(0) translateX(0)"
  },
  "&:hover": {
    "& svg": {
      transform: right === "true" ? "translateX(10px) rotate(180deg)" : "rotate(0) translateX(-10px)"
    }
  },
  "@media (max-width:1024px)": {
    height: "180px",
    "& svg": {
      margin: right === "true" ? "0 0 0 25px" : "0 25px 0 0",
      width: "20px",
      height: "40px",
    },
  },
  "@media (max-width:576px)": {
    padding: right === "true" ? "0 20px 0 0 " : "0 0 0 20px",
    height: "120px",
    "& svg": {
      // margin: right === "true" ? "0 0 0 12px" : "0 12px 0 0",
      width: "14px",
      height: "28px",
    },
  },
}))

const Text = styled(Typography)({
  // width: "70%",
  width: "auto",
  // maxWidth: "75%",
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "44px",
  lineHeight: "120%",
  display: "block",
  alignItems: "center",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textAlign: "center",
  // margin: "0 47px",
  "@media (max-width:1024px)": {
    fontSize: "2rem",
    // margin: "0 7px",
  },
  "@media (max-width:900px)": {
    fontSize: "1.5rem",
    // margin: "0 7px",
  },
  "@media (max-width:576px)": {
    // width: "60%",
    // maxWidth: "60%",
    fontSize: "4.1vw",
    // margin: "0 7px",
  },
})

const BackgroundImage = styled(GatsbyImage)({
  position: "absolute",
  width: "100%",
  height: "230px",
  "& div, img, picture": {
    margin: 0,
  },
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})

const NoiseOne = styled("div")({
  position: "absolute",
  backgroundImage: `url(${NavNoiseOne})`,
  width: "100%",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})
const NoiseTwo = styled("div")({
  position: "absolute",
  backgroundImage: `url(${NavNoiseTwo})`,
  width: "100%",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})

const NavGridItem = styled(Grid)({
  display: "flex",
  width: "100%",
  justifyContent: "end",
  // margin: "0",
  position: "relative",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})

const NavGridItemSecond = styled(NavGridItem)({
  justifyContent: "start",
})

const Container = styled(Grid)({
  position: "relative",
  width: "100%",
  height: "230px",
  "@media (max-width:1024px)": {
    height: "180px",
  },
  "@media (max-width:576px)": {
    height: "120px",
  },
})


const Navigate = ({allCompanies, strapiId}) => {
  const currentIndex = allCompanies.findIndex(item => item.strapi_id === strapiId)
  
  const nextCompanyIndex = (currentIndex + 1) % allCompanies.length;
  const prevCompanyIndex = (currentIndex - 1) === - 1 ? allCompanies.length - 1 : currentIndex - 1

  const nextCompany = {
    name: allCompanies[nextCompanyIndex].name.charAt(0).toUpperCase() + allCompanies[nextCompanyIndex].name.slice(1),
    link: allCompanies[nextCompanyIndex].name.toLowerCase().replace('.', '-').replace(/ /g,'-').replace(/[^\w-]+/g,''),
    image: allCompanies[nextCompanyIndex].background_image.localFile.childImageSharp.gatsbyImageData
  }
  const prevCompany = {
    name: allCompanies[prevCompanyIndex].name.charAt(0).toUpperCase() + allCompanies[prevCompanyIndex].name.slice(1),
    link: allCompanies[prevCompanyIndex].name.toLowerCase().replace('.', '-').replace(/ /g,'-').replace(/[^\w-]+/g,''),
    image: allCompanies[prevCompanyIndex].background_image.localFile.childImageSharp.gatsbyImageData
  }

  return (
    <Wrapper>
      <Container container>
        <NavGridItem item xs={6}>
          <BackgroundImage image={prevCompany.image} alt={prevCompany.name + `-img`} />
          <BlackWrapper />
          <NoiseOne alt={prevCompany.name + `-noise1`}/>
          <NoiseTwo alt={prevCompany.name + `-noise2`}/>
          <BlueWrapper />
          <LinkWrapper to={`/` + prevCompany.link + `/`}>
            <Arrow />
            <Text style={{marginRigth: "20px"}}>{prevCompany.name}</Text>
          </LinkWrapper>
        </NavGridItem>
        <NavGridItemSecond item xs={6}>
          <BackgroundImage image={nextCompany.image} alt={nextCompany.name + `-img`} />
          <BlackWrapper />
          <NoiseOne alt={nextCompany.name + `-noise1`}/>
          <NoiseTwo alt={nextCompany.name + `-noise2`}/>
          <BlueWrapper />
          <LinkWrapper to={`/` + nextCompany.link + `/`} right={"true"}>
            <Text style={{marginLeft: "20px"}}>{nextCompany.name}</Text>
            <Arrow />
          </LinkWrapper>
        </NavGridItemSecond>
      </Container>
    </Wrapper>
  )
}

export default Navigate
