import * as React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
// import { useMediaQuery } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"

const CompanyHeadWrapper = styled("div")({
  width: "100%",
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "28px 0",
  "@media (max-width:1024px)": {
    maxWidth: "100vw",
  },
  "@media (max-width:576px)": {
    padding: "87px 20px 20px",
  },
})

const LogoWrapper = styled("div")({
  // height: "72px",
  // maxWidth: "250px",
  height: "auto",
  maxHeight: "72px",
  "@media (max-width:1024px)": {
    maxWidth: "200px",
    height: "60px",
  },
  "@media (max-width:576px)": {
    maxWidth: "180px",
    height: "52px",
  },
})

const LogoImageWrapper = styled("div")({
  // height: "72px",
  // maxWidth: "350px",
  "& img": {
    width: "auto",
    maxHeight: "72px",
    "@media (max-width:1024px)": {
      maxHeight: "60px",
      maxWidth: "200px",
    },

    "@media (max-width:576px)": {
      maxWidth: "180px",
      maxHeight: "52px",
    },
  },
})

const LogoSvgWrapper = styled("img")({
  height: "72px",
  margin: 0,
  "@media (max-width:1024px)": {
    height: "60px",
  },

  "@media (max-width:576px)": {
    height: "52px",
  },
})

const TextWrapper = styled(Typography)(({ theme }) => ({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "190%",
  display: "block",
  color: theme.palette.secondary.main,
  textAlign: "center",
  width: "100%",
  maxWidth: "806px",
  marginTop: "65.35px",

  "@media (max-width:1280px)": {
    fontSize: "1rem",
    padding: "0 28px",
  },
  "@media(max-width: 1025px)": {
    fontSize: "1.1rem",
  },
  "@media(max-width: 576px)": {
    marginTop: "45px",
    maxWidth: "100%",
    padding: "0 0 28px",
    fontSize: "1.13rem",
  },
}))

const CompanyHero = ({ logo, description }) => {
  // const makeBr = useMediaQuery("(min-width: 1024px)")
  return (
    <CompanyHeadWrapper>
      <LogoWrapper>
        {logo.localFile.childImageSharp !== null ? (
          <LogoImageWrapper>
            <GatsbyImage
              image={logo.localFile.childImageSharp.gatsbyImageData}
              alt={"Company logo"}
            />
          </LogoImageWrapper>
        ) : (
          // <LogoSvgWrapper>
          //   <img src={logo.localFile.publicURL} alt={"Company logo"} />
          // </LogoSvgWrapper>
          <LogoSvgWrapper
            src={`${logo.localFile.publicURL}`}
            alt={`Company logo`}
            loading="lazy"
          />
        )}
      </LogoWrapper>
      <TextWrapper>
        {description}
      </TextWrapper>
    </CompanyHeadWrapper>
  )
}

export default CompanyHero
