import * as React from "react"
import { styled } from "@mui/system"
import { Link, Typography } from "@mui/material"

const CompanyDataWrapper = styled("div")({
  maxWidth: "1280px",
  width: "100%",
  margin: "0 auto",
  height: "100%",
  display: "flex",
  padding: "28px 0",
  // rowGap: "53px",
  justifyContent: "center",
  rowGap: "103px",
  flexWrap: "wrap",

  "@media (max-width:1308px)": {
    padding: "28px 28px",
  },
  "@media (max-width:1024px)": {
    width: "auto",
    // padding: "28px 28px",
    padding: 0,
    margin: "28px 28px",
    rowGap: "80px",
    // Убрать для сетки по линиям!
    paddingLeft: "10%"
  },
  "@media (max-width:576px)": {
    margin: 0,
    padding: "25px 20px",
    rowGap: "42px",
  },
})

const DataContainer = styled("div")({
  width: "25%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  "@media (max-width:1024px)": {
    // width: "auto",
    // margin: "0 8%",
    width: "33%",
    height: "100%",
  },
  "@media (max-width:576px)": {
    width: "50%",
    height: "100%",
  },
})

const DataTitle = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.palette.secondary.main,
  opacity: 0.5,
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "25px",
  letterSpacing: "-0.01em",
  cursor: "default",
  "@media (max-width:1280px)": {
    fontSize: "1.3rem",
  },
  "@media(max-width: 1025px)": {
    fontSize: "1.1rem",
  },
  "@media(max-width: 576px)": {
    fontSize: "14px",
  },
}))

const DataDescription = styled(Typography)(({ color }) => ({
  // width: "auto",
  width: "100%",
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "150%",
  display: "block",
  color: color,
  marginTop: "18px",
  cursor: "default",
  "@media (max-width:1280px)": {
    fontSize: "1.3rem",
  },
  "@media(max-width: 1025px)": {
    fontSize: "1.1rem",
  },
  "@media(max-width: 576px)": {
    fontSize: "1.13rem",
  },
}))

const CompanyData = ({ data }) => {
  const companyUrl = data.website.includes("http://") || data.website.includes("https://") ? data.website : "https://" + data.website
  return (
    <CompanyDataWrapper>
      <DataContainer>
        <DataTitle>YEAR FOUNDED</DataTitle>
        <DataDescription color={data.color}>
          {data.year_founded}
        </DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>LOCATION</DataTitle>
        <DataDescription color={data.color}>{data.location}</DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>SECTOR</DataTitle>
        <DataDescription color={data.color}>{data.sector}</DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>FOUNDERS</DataTitle>
        <DataDescription color={data.color}>
          {data.leader.map(leader => (
            <span key={leader.name + ""}>
              {leader.name}
              <br />
            </span>
          ))}
        </DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>YEAR INVESTED</DataTitle>
        <DataDescription color={data.color}>
          {data.year_invested}
        </DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>STAGE</DataTitle>
        <DataDescription color={data.color}>{data.stage}</DataDescription>
      </DataContainer>
      <DataContainer>
        <DataTitle>INVESTMENT STATUS</DataTitle>
        <DataDescription color={data.color}>
          {data.status}
        </DataDescription>
      </DataContainer>
      <DataContainer>
        <Link target="_blank" rel="noopener noreferrer" href={companyUrl} underline="none">
          <DataTitle style={{ cursor: "pointer" }}>company website</DataTitle>
          <DataDescription color={data.color} style={{ cursor: "pointer" }}>
            {data.website}
          </DataDescription>
        </Link>
      </DataContainer>
    </CompanyDataWrapper>
  )
}

export default CompanyData
