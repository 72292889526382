import React from "react"
import { graphql } from "gatsby"

import GridLines from "../components/gridLines"
import CompanyHero from "../components/companyTemplate/hero"
import CompanyData from "../components/companyTemplate/dataOfCompany"
import News from "../components/news"
import Navigate from "../components/companyTemplate/navigate"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Company = ({
  pageContext,
  data: { strapiCompany, allStrapiNewsItem, allStrapiCompany },
}) => {
  const navData = allStrapiCompany.edges.map(item => item.node)
  const hasNews = allStrapiNewsItem.edges !== undefined && allStrapiNewsItem.edges.length !== 0

  return (
    <>
      <Layout headerDark={false}>
        <Seo title={strapiCompany.name} />
        <CompanyHero logo={strapiCompany.logo} description={strapiCompany.description}/>
        <GridLines
          styles={{
            height: 174,
            heightMob: 60,
            // marginBottom: 28,
            color: "#F8F8F8",
          }}
        />
        <CompanyData data={strapiCompany} />
        <GridLines
          styles={{
            height: 144,
            heightMob: 30,
            marginTopMob: 43,
            color: "#F8F8F8",
          }}
        />
        {hasNews === true ? (
          <News
            news={allStrapiNewsItem}
            showHeader={false}
            mobileMaxNews={6}
            strapiId={strapiCompany.strapi_id}
          />
        ) : null}

        <Navigate allCompanies={navData} strapiId={pageContext.strapi_id} />
      </Layout>
    </>
  )
}

export default Company

export const pageQuery = graphql`
  query CompanyBySlug($strapi_id: Int!) {
    strapiCompany(strapi_id: { eq: $strapi_id }) {
      strapi_id
      status
      location
      motto
      name
      background_image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      year_invested
      year_founded
      website
      sector
      color
      description
      stage
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
      leader {
        name
      }
    }
    allStrapiNewsItem(filter: { company: { strapi_id: { eq: $strapi_id } } }) {
      edges {
        node {
          strapi_id
          source_link
          source_name
          blog_type {
            Type
          }
          title
          publishedAt
          publication_date
        }
      }
    }
    allStrapiCompany {
      edges {
        node {
          background_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          strapi_id
          name
        }
      }
    }
  }
`
